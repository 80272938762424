import { ReactComponent as FactoryIco } from './../../img/factory-ico.svg';
import { ReactComponent as CoalIco } from './../../img/coal-ico.svg';
import { ReactComponent as EnergyIco } from './../../img/energy-ico.svg';
import { ReactComponent as WaterIco } from './../../img/water-ico.svg';
import { ReactComponent as TubeIco } from './../../img/tube-ico.svg';
import { ReactComponent as GlassIco } from './../../img/glass-ico.svg';



let CheckNumbers = props =>  {
    let translations = props.translations;

    return (
      <div className="app-check-numbers" id="results">
          <div className="title">
              <h3>{ translations.table_title }</h3>

              <div className={"items" + ( props.manualMeters && props.manualMeters.length >= 4  ? ' long-result' : '') } >
                  <div className="item">
                      <div className="upcyc-icon factory">
                        <FactoryIco />
                      </div>
                      <div className="text">
                          <p>{ translations.table_row1 }</p>
                      </div>
                      <div className="result">
                          <div className="number">
                              { props.gas.toFixed(2) }
                          </div>
                          <div className="ext">
                              kg CO2
                          </div>
                      </div>
                  </div>

                  <div className="item">
                      <div className="upcyc-icon coal">
                        <CoalIco />
                      </div>
                      <div className="text">
                          <p>{ translations.table_row2 }</p>
                      </div>
                      <div className="result">
                          <div className="number">
                            { props.mining.toFixed(2) }
                          </div>
                          <div className="ext">
                              kg
                          </div>
                      </div>
                  </div>

                  <div className="item">
                      <div className="upcyc-icon energy">
                        <EnergyIco />
                      </div>
                      <div className="text">
                          <p>{ translations.table_row3 }</p>
                      </div>
                      <div className="result">
                          <div className="number">
                              { props.energy.toFixed(2) }
                          </div>
                          <div className="ext">
                              MJ
                          </div>
                      </div>
                  </div>

                  <div className="item">
                      <div className="upcyc-icon water">
                        <WaterIco />
                      </div>
                      <div className="text">
                          <p>{ translations.table_row4 }</p>
                      </div>
                      <div className="result">
                          <div className="number">
                              { props.water.toFixed(2) }
                          </div>
                          <div className="ext">
                              l
                          </div>
                      </div>
                  </div>

                  <div className="item">
                      <div className="upcyc-icon tube">
                        <TubeIco />
                      </div>
                      <div className="text">
                          <p>{ translations.table_row5 }</p>
                      </div>
                      <div className="result">
                          <div className="number">
                              { props.sewage.toFixed(2) }
                          </div>
                          <div className="ext">
                              l
                          </div>
                      </div>
                  </div>

                  <div className="item">
                      <div className="upcyc-icon glass">
                        <GlassIco />
                      </div>
                      <div className="text">
                          { props.manualMeters && 
                            <p>{ props.manualMeters.length < 4 ?  translations.table_row6 : translations.table_row6Short }</p>
                          }
                          { !props.manualMeters && 
                            <p>{ translations.table_row6 }</p>
                          }
                      </div>
                      <div className="result">
                          <div className="number">
                            { props.chemicals.toFixed(2) }
                          </div>
                          <div className="ext">
                              g
                          </div>
                      </div>
                  </div>
                  
              </div>
          </div>
      </div>
    );
  }
  
export default CheckNumbers;
  